import React from 'react';
import CSSModules from 'react-css-modules';
import Cookies from 'universal-cookie';

import NavHead from '../NavHead';
import LayoutContainer from '../EditorPanel/ManageSections/LayoutContainer';
import CookieWarning from '../CookieWarning';
import styles from './styles.module.css';

const cookies = new Cookies();
cookies.get('cookiesAccepted');

class TabletView extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      matches: null,
      isScrolling: false,
    };

    this.updateMatches = this.updateMatches.bind(this);
    this.documentReady = this.documentReady.bind(this);
    this.update = this.update.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  update(target, value) {

    this.setState({
      [target]: value,
    });

  }

  documentReady(callbackFunction) {

    if (typeof document !== 'undefined') {

      if (document.readyState !== 'loading') {

        callbackFunction();

      } else {

        document.addEventListener("DOMContentLoaded", callbackFunction);

      }

    }

  }

  render() {

    let ready = false;
    if (typeof document !== 'undefined') {

      this.documentReady(() => {
        ready = true;
      });

    }

    const render = [];
    let overlay;

    this.props.pageContext.page.sections.forEach((section, index) => {

      if (section.type === 'NAVIGATION') {

        ({ overlay } = section.data[0]);

      } else {

        render.push(<LayoutContainer
          pageContext={this.props.pageContext}
          key={`Layout_${this.props.pageContext.page.sections[index]._id}`}
          section={this.props.pageContext.page.sections[index]}
          overlay={index === 1 && overlay === true}
          matches={this.state.matches}
          isScrolling={this.state.isScrolling}
        />);

      }

    });

    return (
      <div className={styles.renderWrapper}>
        <div className={styles.renderContainer}>
          {
            render.length > 0
            && (
              <div id="tablet-container" className={styles.tabletContainer}>
                <NavHead update={this.update} pageContext={this.props.pageContext} />
                { render }
              </div>
            )
          }
          {
            (
              this.props.pageContext.cookie
              && this.props.pageContext.cookie.active === true
              && cookies
              && cookies.cookies
              && cookies.cookies.cookiesAccepted === undefined
              && ready === true
            ) && (
              <CookieWarning colors={this.props.pageContext.themeData.colors} cookie={this.props.pageContext.cookie} />
            )
          }
        </div>
      </div>
    );

  }

}

export default CSSModules(TabletView, styles, { allowMultiple: true });
